export enum PRODUCT_IDS {
  REDEEM_Y = 'redeem_Y',
  REDEEM_M = 'redeem_M',
  REDEEM_M_L = 'redeem_M_L',
}

export const PRODUCTS = {
  [PRODUCT_IDS.REDEEM_Y]: {
    id: PRODUCT_IDS.REDEEM_Y,
    period: 'p1y',
    badge: 'save',
    price: '🔥 2.50$',
    link: 'https://watchface.onelink.me/OCF7/h2le4hbu',
  },
  [PRODUCT_IDS.REDEEM_M]: {
    id: PRODUCT_IDS.REDEEM_M,
    period: 'p1m',
    badge: 'popular',
    price: '9.99$',
    link: 'https://watchface.onelink.me/OCF7/kzfharhv',
  },
  [PRODUCT_IDS.REDEEM_M_L]: {
    id: PRODUCT_IDS.REDEEM_M_L,
    period: 'p1m',
    badge: 'popular',
    price: '5.99$',
    link: 'https://watchface.onelink.me/OCF7/id3hfmmo',
  }
};
