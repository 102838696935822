export const APP_NAME = process.env.REACT_APP_TITLE;

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const METRIX_API_URL = process.env.REACT_APP_METRIX_API_URL;

export const METRIX_TOKEN = process.env.REACT_APP_METRIX_TOKEN;

export const LANDING_TYPE = process.env.REACT_APP_LANDING_TYPE;

export const APP_LINKS: any = {
  APPSFLYER_LINK: process.env.REACT_APP_AF_LINK,
};

export const EVENT_ACTION = {
  OPEN: 'open',
  SUCCESS: 'success',
  CLICK: 'click',
  ERROR: 'error',
  SKIP: 'skip',
  CLOSE: 'close',
};

export const LANDING_TYPES = {
  PRO: 'pro',
  BEST: 'best',
  PLANS: 'plans',
};

export const PAGE_TYPES = {
  SURVEY: 'survey',
  PLAN: 'plan',
  CANCEL: 'cancel',
};