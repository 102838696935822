import {
  SET_LOADED,
  SET_REDEEM_INSTRUCTIONS_MODAL_VISIBLE,
} from './types';

export const initialState = {
  loaded: false,
  isRedeemInstructionsModalVisible: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };
    case SET_REDEEM_INSTRUCTIONS_MODAL_VISIBLE:
      return {
        ...state,
        isRedeemInstructionsModalVisible: action.payload,
      };

    default:
      return state;
  }
};

reducer.blacklist = ['loaded'];
reducer.whitelist = ['isRedeemInstructionsModalVisible'];

export default reducer;
