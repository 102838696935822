import * as TYPES from './types';

export const initialState = {
  authorized: false,
  pending: false,
  email: '',
  uuid: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
  
    case TYPES.SET_AUTHORIZED:
      return {
        ...state,
        authorized: true,
      };

    case TYPES.SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };

    case TYPES.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };

    case TYPES.RESET_EMAIL:
      return {
        ...state,
        email: state.email,
      };

    default:
      return state;
  }
};

reducer.blacklist = ['authorized', 'pending'];

export default reducer;
