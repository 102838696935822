// import { parseUrlParams } from 'utils/url-sync';

import { render } from './render';

// parseUrlParams();

const pageType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pageType) {
  // case 'cancel':
  //   promiseApp = import('cancel');
  //   break;
  // default:
  //   promiseApp = import('./pages/survey');
  //   break;
  default:
    promiseApp = import('./pages/landing');
    break;
}

promiseApp.then(({ default: App }) => {
  render(App);
});
