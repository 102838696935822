import _merge from 'lodash/merge';
import _forEach from 'lodash/forEach';
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import interpolate from 'interpolate';
import dayjs from 'dayjs';

require('dayjs/locale/de')
// require('dayjs/locale/es-mx')
require('dayjs/locale/es')
require('dayjs/locale/fr')
// require('dayjs/locale/pt-br')
require('dayjs/locale/pt')

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
  de: {
    translation: require('./locales/de.json'),
  },
  it: {
    translation: require('./locales/it.json'),
  },
  es: {
    translation: require('./locales/es.json'),
  },
  fr: {
    translation: require('./locales/fr.json'),
  },
  pt: {
    translation: require('./locales/pt.json'),
  },
};

let globalOptions;

function init(localization, options) {
  globalOptions = options;
  localization = _merge({}, localization);

  _forEach(localization, (v, k) => {
    localization[k] = _merge(resources[k], localization[k]);
  });

  let lng = (navigator.language || 'en').replace('_', '-').toLowerCase();

  if (!localization[lng]) {
    lng = lng.split('-')[0];
  }
  if (!localization[lng]) {
    lng = 'en';
  }

  dayjs.locale(lng);

  i18next.init({
    lng,
    fallbackLng: 'en',
    resources: localization,
    lowerCaseLng: true,
    react: {
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
    },
  });
}

function processOptions(options) {
  return Object.assign({}, globalOptions, options);
}

export function t(key, options) {
  return i18next.t(key, processOptions(options));
}

export function tm(lngs, defaultKey, options) {
  return (
    interpolate(
      (typeof lngs === 'string'
        ? lngs
        : lngs?.[i18next.language] || lngs?.[i18next.language.split('-')[0]]) || '',
      processOptions(options)
    )
    ||
    i18next.t(defaultKey, processOptions(options))
  );
}

const Localization = {
  init,
  t,
  tm,
};

export { Localization };

export const T = ({ k, tOptions, ...other }) => (
  <Trans
    i18n={i18next}
    i18nKey={k}
    tOptions={processOptions(tOptions)}
    {...other}
  />
);

T.propTypes = {
  k: PropTypes.string.isRequired,
};
