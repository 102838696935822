import {
  RESET,
  SET_IS_SUBMIT_BY_CARD,
  SET_LOADING,
  SET_PURCHASE,
  SET_ORDER_DETAILS,
  SET_ORDER_PENDING,
  SET_PRODUCTS,
  SET_DISCOUNT_END_DATE,
  SET_SPECIAL_OFFER_END_DATE,
  SET_PENDING,
  SET_TRIAL_PRICE,
  SET_SPECIAL_OFFER_SUGGESTED,
  SET_SELECTED_PRODUCT,
} from './types';

import { PRODUCT_IDS } from 'constants/products';

export const initialState = {
  // purchase: null,
  // purchased: false,
  // loading: true,
  // pending: false,
  // isSubmitByCard: false,
  // orderDetails: null,
  // orderPending: false,
  // products: [],
  selectedProduct: PRODUCT_IDS.REDEEM_Y,
  offerEndDate: null,
  discountEndDate: null,
  // specialOfferSuggested: false,
  // trialPrice: null,
};

const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_PURCHASE:
      return {
        ...state,
        purchase: action.payload.purchase,
        purchased: !!action.payload.purchase,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case SET_IS_SUBMIT_BY_CARD:
      return {
        ...state,
        isSubmitByCard: action.payload,
      };

    case SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case SET_ORDER_PENDING:
      return {
        ...state,
        orderPending: action.payload,
      };

    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case SET_DISCOUNT_END_DATE:
      return {
        ...state,
        discountEndDate: action.payload,
      };

    case SET_SPECIAL_OFFER_END_DATE:
      return {
        ...state,
        offerEndDate: action.payload,
      };

    case SET_SPECIAL_OFFER_SUGGESTED:
      return {
        ...state,
        specialOfferSuggested: action.payload,
      };

    case SET_TRIAL_PRICE:
      return {
        ...state,
        trialPrice: action.payload,
      };

    case RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

reducer.whitelist = ['subscriptionDetails', 'purchase', 'purchased', 'discountEndDate', 'trialPrice', 'offerEndDate', 'selectedProduct'];

export default reducer;
