import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

// import { auth } from './store/profile/actions';
import { Localization } from './modules/localization';

import localization from './localization';
import configureStore from './store';

import { APP_NAME, SUPPORT_EMAIL } from './constants/general';
import { PAGE_TYPES } from './constants/general';

import 'styles/global.scss';
import 'styles/index.scss';

const App = (AppRouter) => {
  const pageType = window.location.pathname.split('/')[1];
  const { store, persistor } = configureStore();

  Localization.init(localization, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
  if (pageType !== PAGE_TYPES.CANCEL) {
    (async () => {
      const { load } = await import('./store/app/actions');
      store.dispatch(load());
      // store.dispatch(auth());
    })();
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <ToastContainer position="bottom-center" autoClose={4000} closeOnClick /> */}
          <AppRouter />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

export const render = (AppRouter) => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(App(AppRouter));
};
