import en from './en.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import pt from './pt.json';

 const resources = {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
    pt: {
      translation: pt,
    },
  };

  export default resources;
  