import { SET_ANSWERS } from './types';

const initialState = {
  answers: {},
};

const survey = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          ...action.payload,
        }
      };
    default:
      return state;
  }
};

export default survey;
