import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import app from './app';
import routing from './routing';
import profile from './profile';
import billing from './billing';
import survey from './survey';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const rootReducer = combineReducers({
    app: persistReducer(
      {
        key: 'app',
        storage,
        blacklist: app.blacklist,
        whitelist: app.whitelist,
      },
      app,
    ),
    routing: persistReducer(
      {
        key: 'routing',
        storage,
        blacklist: routing.blacklist,
        whitelist: routing.whitelist,
      },
      routing,
    ),
    billing: persistReducer(
      {
        key: 'billing',
        storage,
        blacklist: billing.blacklist,
        whitelist: billing.whitelist,
      },
      billing,
    ),
    profile: persistReducer(
      {
        key: 'profile',
        storage,
        blacklist: profile.blacklist,
        whitelist: profile.whitelist,
      },
      profile,
    ),
    survey: persistReducer(
      {
        key: 'survey',
        storage,
        blacklist: survey.blacklist,
        whitelist: survey.whitelist,
      },
      survey,
    ),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
