export const SET_PURCHASE = 'BILLING/SET_PURCHASE';
export const SET_LOADING = 'BILLING/SET_LOADING';
export const SET_PENDING = 'BILLING/SET_PENDING';
export const SET_IS_SUBMIT_BY_CARD = 'BILLING/SET_IS_SUBMIT_BY_CARD';
export const SET_ORDER_DETAILS = 'BILLING/SET_ORDER_DETAILS';
export const SET_ORDER_PENDING = 'BILLING/SET_ORDER_PENDING';
export const SET_PRODUCTS = 'BILLING/SET_PRODUCTS';
export const SET_DISCOUNT_END_DATE = 'BILLING/SET_DISCOUNT_END_DATE';
export const SET_SPECIAL_OFFER_END_DATE = 'BILLING/SET_SPECIAL_OFFER_END_DATE';
export const SET_SPECIAL_OFFER_SUGGESTED = 'BILLING/SET_SPECIAL_OFFER_SUGGESTED';
export const SET_TRIAL_PRICE = 'BILLING/SET_TRIAL_PRICE';
export const RESET = 'BILLING/RESET';
export const SET_SELECTED_PRODUCT = 'BILLING/SET_SELECTED_PRODUCT';
